import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {Button, Card, CardBody, Col, Container, Row} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import TrackingForm from '../components/TrackingForm';
import EnvioData from '../components/EnvioData';

const TrackingPage = ({t}) => {

  const [code, setCode] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [envio, setEnvio] = useState('');
  const [triggerFind, setTriggerFind] = useState(false);


  useEffect(() => {
    const codeVar = getQueryVariable('tracking');
    if(codeVar) {
      setCode(codeVar);
      setTriggerFind(val => !val);
    }
  }, [])

  function getQueryVariable(variable)
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }

  return (
    <>
    <Container>
          <Row className={'justify-content-center'}>
            <Col lg={!!envio ? 6:4} md={!!envio ? 8:6} className={"mt-3"}>
              <TrackingForm code={code} setCode={setCode} setEnvio={setEnvio} triggerFind={triggerFind} simple={!!envio}/>
            </Col>
            {!!envio &&
              <Col lg={12} className={"mt-3 pt-4"}>
                <EnvioData envio={envio} setEnvio={setEnvio} />
              </Col>
            }
          </Row>
    </Container>
    </>
  )
}

export default withTranslation()(TrackingPage);