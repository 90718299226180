import logo from './logo.svg';
import './styles/App.css';
import './i18n';
import {withTranslation} from 'react-i18next';
import TrackingPage from './pages/TrackingPage';

function App({t}) {

  document.title = t('Divain Tracking');
  return (
    <div className="App">
      <header className="App-header py-4">
        <a href={`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`}>
          <img src={logo} className="App-logo" alt="Divain" />
        </a>
      </header>
      <main>
        <TrackingPage />
      </main>
      {/*<footer>
        <p className={'fs-6 my-2'}>DIVAIN © 2024</p>
      </footer>*/}
    </div>
  );
}

export default withTranslation()(App);
