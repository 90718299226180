import {APIClient} from './apiHelper';

const apiClient = new APIClient();

export const getShipmentSimple = async (data) => {
  return  apiClient.post('pedidos/envio', data);
}

export const getShipmentExtended = async (data) => {
  return  apiClient.post('pedidos/envioExtended', data);
}