import {withTranslation} from 'react-i18next';
import {Button, Card, CardBody, Col, Row, Spinner} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import React, {useEffect, useState} from 'react';
import {getShipmentSimple} from '../helpers/API/api';

const TrackingForm = ({t, code, setCode, setEnvio, triggerFind, simple =false}) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const find = async () => {
    if(!loading){
      setLoading(true);
      setError('');
      try{
        const response = await getShipmentSimple({code});
        if(!response.data.status){
          response.data.status = {
            codigo: 0,
            descripcion: 'En preparación',
          };
        }
        setEnvio(response.data);
        setLoading(false);
        return response.data;
      } catch (e) {
        console.error(e);
        if(e.status == 404){
          setError('Shipment code not found');
        }else{
          setError('There is a problem. Try again.');
        }
      }
      setLoading(false);
      return null;
    }
  }

  useEffect(() => {
    if(code) {
      find().then((envioData) => {
        if(envioData.carrier === 'SEUR' && envioData.country === 'FR' && envioData.outsideCountryOrigin){
          window.location.href = envioData.trackingURL;
        }
      })
    }
  }, [triggerFind]);

  if(simple){
    return (
      <>
        <Card className={'border-black shadow mt-4'}>
          <CardBody>
            <Row>
              <Col md={8}>
                <p className={'text-secondary mb-1'}>{t('Set your shipment number')}</p>
                <Form.Group className="" controlId="code">
                  <Form.Control type="text" className={'border-0 fw-bold px-0'} placeholder={t('Shipment code')} value={code} name={'code'} onChange={e => {
                    setCode(e.target.value)
                  }}/>
                  {error && <p className={'text-danger fs-6'}>{t(error)}</p>}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Col sm={12} className={'text-center'}>
                  <Button variant={'primary'} className={'w-100 border-black py-3 text-uppercase'} onClick={find}>{
                    loading ?
                      <>
                        <Spinner as="span"
                                 animation="border"
                                 size="sm"
                                 role="status"
                                 aria-hidden="true" role="status" variant="secondary"/>
                        &nbsp;
                        {t('Loading')}...
                      </>
                      :
                      t('Find')}
                  </Button>
                </Col>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    )
  }
  return (
    <>
      <Card className={'border-black shadow mt-4'}>
        <CardBody>
          <Row className={'justify-content-center text-center'}>
            <Col sm={12}>
              <h1>{t('Where\'s my shipment?')}</h1>
              <p>{t('Track your Divain shipment')}</p>
            </Col>
          </Row>
          <Row className={'justify-content-center'}>
            <Col sm={12}>
              <Form.Group className="mb-3 " controlId="code">
                <Form.Control type="text" className={'border-black py-3'} placeholder={t('Shipment code')} value={code} name={'code'} onChange={e => {
                  setCode(e.target.value)
                }}/>
                {error && <p className={'text-danger fs-6'}>{t(error)}</p>}
              </Form.Group>
            </Col>
            {/*<Col lg={4} sm={12}>
              <Form.Group className="mb-3" controlId="postalCode">
                <Form.Control type="text" placeholder={t('Postal code')} value={postalCode} name={'postalCode'}
                              onChange={e => {
                                setPostalCode(e.target.value)
                              }}/>
              </Form.Group>
            </Col>*/}
          </Row>
          <Row className={'justify-content-center'}>
            <Col sm={12} className={'text-center'}>
              <Button variant={'primary'} className={'w-100 border-black py-3 text-uppercase'} onClick={find}>{
                loading ?
                  <>
                    <Spinner as="span"
                             animation="border"
                             size="sm"
                             role="status"
                             aria-hidden="true" role="status" variant="secondary"/>
                    &nbsp;
                    {t('Loading')}...
                  </>
                  :
                  t('Find')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default withTranslation()(TrackingForm);