import {withTranslation} from 'react-i18next';
import {Button, Card, CardBody, Col, Row, Spinner} from 'react-bootstrap';
import house from '../assets/img/house.svg';
import shipping from '../assets/img/shipping.svg';
import order from '../assets/img/order.svg';
import deleted from '../assets/img/deleted.svg';
import Form from 'react-bootstrap/Form';
import React, {useEffect, useState} from 'react';
import {getShipmentExtended, getShipmentSimple} from '../helpers/API/api';
import up from '../assets/img/up.svg';
import down from '../assets/img/down.svg';

const EnvioData = ({t, envio, setEnvio}) => {

  const images = [order, shipping, house, deleted];

  const [postalCode, setPostalCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [expanded, setExpanded] = useState(false);


  useEffect(() => {
    setPostalCode('');
  }, [envio]);
  const find = async () => {
    if(!loading){
      setLoading(true);
      setError('');
      try{
        const response = await getShipmentExtended({code: envio.shipmentCode, postalCode});
        if(!response.data.status){
          response.data.status = {
            codigo: 0,
            descripcion: 'En preparación',
          };
        }
        setEnvio(response.data);
        setExpanded(true);
      } catch (e) {
        console.error(e);
        switch (e.status) {
          case 400:
            setError('You must set destination postal code');
            break
          case 404:
            setError('Incorrect postal code');
            break;
          default:
            setError('There is a problem. Try again.');
        }
      }
      setLoading(false);
    }
  }
  return(
    <>
      <Card className={'border-black shadow'}>
        <CardBody>
          <Row className={'justify-content-center border-bottom border-black'}>
            {envio.status.codigo === 4 &&
              <Col sm={12} >
                <p className={'text-uppercase mx-4'}>{t('Status')}: <span className={'fw-bold text-danger'}>{t('Returned')}</span> </p>
              </Col>
            }
            <Col sm={12}>
              <div className="progress-bar-container">
                <div className={`step ${envio.status.codigo <= 1 ? 'active' : ''}`}>
                  <div className="circle"></div>
                  <div className="label">{t('Processing')}</div>
                </div>
                <div className={`line`}></div>
                <div className={`step ${envio.status.codigo === 2 ? 'active' : ''}`}>
                  <div className="circle"></div>
                  <div className="label">{t('In transit')}</div>
                </div>
                <div className={`line`}></div>
                <div className={`step ${envio.status.codigo === 3 ? 'active' : ''}`}>
                  <div className="circle">
                    {envio.status.codigo === 4 &&
                      <p style={{transform: 'translateY(-3px)'}}><span className={'text-danger m-0 fw-bold'}>X</span></p>
                    }
                  </div>
                  <div className="label">{t('Delivered!')}</div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className={'mx-2 my-4'}>
            <Col xs={10}>
                <p className={'mb-0'}>{t('Shipment code')}:</p>
                <p className={'fw-bold '}>{envio.shipmentCode}</p>
                <p className={'mb-0'}>{t('Destination')}:</p>
                <p className={'fw-bold '}>{envio.city} ({envio.country})</p>
            </Col>
            {!!envio.name &&
              <>
                <Col xs={2} className={'d-flex'}>
                  <div className={'ms-auto'}>
                    <img className={'img-button'} style={{width: '100%', maxWidth: '100px', minWidth: '40px'}} src={expanded ? up : down}
                         onClick={() => setExpanded(prevState => !prevState)}/>
                  </div>
                </Col>
              </>
            }
          </Row>
          {!envio.name &&
            <Row className={'mx-2'}>
            <Col md={6}>
                <p className={'fs-3 fw-light pe-4'}>{t('If you want to see more data, set shipment postal code')}</p>
              </Col>
              <Col md={6}>
                <Row className={'border border-black py-2 justify-content-center align-items-center'}>
                  <Col md={6} >
                    <Form.Group controlId="postalCode">
                      <Form.Control type="text" className={'border-0 fw-bold'} placeholder={t('Postal code')} value={postalCode} name={'postalCode'}
                                    onChange={e => {
                                      setPostalCode(e.target.value)
                                    }}/>
                    </Form.Group>
                  </Col>
                  <Col md={6} className={'d-flex justify-content-start'}>
                    <Button variant={'primary'} className={'w-100 border-black py-3 text-uppercase'} onClick={find}>{
                      loading ?
                        <>
                          <Spinner as="span"
                                   animation="border"
                                   size="sm"
                                   role="status"
                                   aria-hidden="true" role="status" variant="secondary"/>
                          &nbsp;
                          {t('Loading')}...
                        </>
                        :
                        t('Show details')}
                    </Button>
                  </Col>
                  {error && <p className={'text-danger fs-6'}>{t(error)}</p>}
                </Row>
              </Col>
            </Row>
          }
          {!!envio.name && expanded &&
            <>
              <Row className={'mx-2'}>
                <Col md={4}>
                  <p className={'mb-0'}>{t('Name')}</p>
                  <p className={'fw-bold '}>{envio.name}</p>
                </Col>
                <Col md={4}>
                  <p className={'mb-0'}>{t('Order number')}</p>
                  <p className={'fw-bold '}>{envio.orderNumber}</p>
                </Col>
                <Col md={4}>
                  <p className={'mb-0'}>{t('Packages')}</p>
                  <p className={'fw-bold '}>{envio.packages}</p>
                </Col>
              </Row>
              <Row className={'mx-2'}>
                <Col md={4}>
                  <p className={'mb-0'}>{t('Address')}</p>
                  <p className={'fw-bold '}>{[envio.address, envio.address2].join(' ')}</p>
                </Col>
                <Col md={4}>
                  <p className={'mb-0'}>{t('Postal code')}</p>
                  <p className={'fw-bold '}>{envio.postalCode}</p>
                </Col>
              </Row>
              <Row className={'mx-2'}>
                <Col md={4}>
                  <p className={'mb-0'}>{t('Phone')}</p>
                  <p className={'fw-bold '}>{envio.phone}</p>
                </Col>
                <Col md={4}>
                  <p className={'mb-0'}>{t('Email')}</p>
                  <p className={'fw-bold '}>{envio.email}</p>
                </Col>
              </Row>
            </>
          }
          {(
            !envio.trackingURL.startsWith(`http://${window.location.hostname}`) &&
            !envio.trackingURL.startsWith(`https://${window.location.hostname}`)
            ) &&
            <>
              <Row className={'border-top border-black mt-4'}></Row>
              <Row className={'mt-4 mx-2'}>
                <Col md={6} >
                  <p className={'fw-semibold'}>{t('More info from carrier')}</p>
                </Col>
                <Col md={1}></Col>
                <Col md={5}>
                  <Button href={envio.trackingURL} className={'w-100 text-uppercase py-3'} variant={'secondary'}>{t('Contact carrier')}</Button>
                </Col>
              </Row>
            </>
          }
        </CardBody>
      </Card>
    </>
  )
}

export default withTranslation()(EnvioData);